@import 'utils';

.menu {
  .back_button {
    align-items: center;
    background-color: var(--color-neutral-300);
    display: flex;
    justify-content: center;
    padding: spacing(5);
    position: relative;
    text-transform: uppercase;
    width: 100%;

    svg {
      left: 6px;
      position: absolute;
    }
  }
}

.submenu_list {
  height: stretch;
  overflow-y: auto;
  position: absolute;
  width: 100%;
}
