@import 'utils';

.wrapper {
  height: 100vh;
  inset: 0;
  position: fixed;
  width: 100vw;
  z-index: 200;

  // reset
  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    outline: revert;
  }

  .menu {
    height: 100%;
    position: relative;

    a,
    button {
      &:hover,
      &:focus {
        color: var(--color-utility-interaction-600, #0000d3);
      }
    }
  }

  ul {
    list-style: none;
    padding: 0 spacing(5);
  }

  li > ul {
    padding-right: 0;
  }

  ul > li {
    font-weight: 500;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral-500);
    }

    // menu item
    a,
    button {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: spacing(4) 0;
      text-transform: uppercase;
      width: 100%;
    }

    a {
      line-height: 1.5;
    }
  }
}

.drawer_content {
  background: var(--color-background-hex, #fff);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 360px;
  padding-bottom: spacing(6);
  position: relative;
  width: 100%;
}

.drawer_overlay {
  background-color: rgb(0 0 0 / 50%);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.header {
  border-bottom: 1px solid var(--color-neutral-500);
  padding: spacing(4) spacing(5);
  position: relative;

  .close_button {
    position: absolute;
    right: spacing(5);
    top: spacing(4);
  }
}

.footer {
  background-color: var(--color-background-hex);
  margin-top: auto;
  padding: spacing(5);
  z-index: 1;
}
