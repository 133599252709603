@import 'utils';

.private_communities {
  margin-top: spacing(4);
}

.title {
  color: var(--color-neutral-600);
  text-transform: uppercase;
}
